import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { FiCast , FiLayers , FiUsers , FiMonitor,FiBriefcase,FiTag,FiCode ,FiChevronUp,FiFilter, FiMail, FiCopy,FiAirplay,FiSearch } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiBriefcase />,
        title: 'Business Strategy',
        description: 'Right from differentiated product or service strategy to a focus on niche strategy or cost strategy, we plan and build what is best for end user.'
    },
    {
        icon: <FiTag />,
        title: 'Brand Identity',
        description: 'We implement brand strategy that develops brand identity, brand image, brand culture and brand personality which increases brand trust, loyalty and awareness.'
    },
    {
        icon: <FiCode />,
        title: 'Design & Development',
        description: 'Right from design concept, an idea to features & functionalities, we provide website and mobile apps which delivers results and are users and device friendly.'
    },
    {
        icon: <FiSearch />,
        title: 'Search Engine Optimization',
        description: 'SEO strategies focussed on driving more traffic to the website with tested tactics that works best for the business.'
    },
    {
        icon: <FiFilter />,
        title: 'Paid Permotions',
        description: 'Right from Search Advertising, Google Shopping Ads, Display Advertising, Social Media Advertising, Remarketing, Mobile Advertising and Google Adwords Campaign, we deliver all.'
    },
    {
        icon: <FiCopy />,
        title: 'Social Media',
        description: 'With focus on planning and implementing social media strategies on the right social media platform is the key to success in building relationship with your right audience.'
    },
]




const BusinessStrategy = [
    {
        icon: <FiBriefcase />,
        title: 'Business Strategy',
        description: 'Right from differentiated product or service strategy to a focus on niche strategy or cost strategy, we plan and build what is best for end user.'
    },
    
]


const BrandIdentity = [
    {
      icon: <FiTag />,
        title: 'Brand Identity',
        description: 'We implement brand strategy that develops brand identity, brand image, brand culture and brand personality which increases brand trust, loyalty and awareness.'
    },
    
]
const DesignDevelopment = [
    {
       icon: <FiCode />,
        title: 'Design & Development',
        description: 'Right from design concept, an idea to features & functionalities, we provide website and mobile apps which delivers results and are users and device friendly.'
     },
    
]
const SearchEngieOptimization = [
    {
        icon: <FiSearch />,
        title: 'Search Engine Optimization',
        description: 'SEO strategies focussed on driving more traffic to the website with tested tactics that works best for the business.'
    },
    
]
const PayPerClick = [
    {
       icon: <FiFilter />,
        title: 'Paid Promotions',
        description: 'Right from Search Advertising, Google Shopping Ads, Display Advertising, Social Media Advertising, Remarketing, Mobile Advertising and Google Adwords Campaign, we deliver all.'
    },
    
]

const SocialMedia = [
    {
        icon: <FiCopy />,
        title: 'Social Media Marketing',
        description: 'With focus on planning and implementing social media strategies on the right social media platform is the key to success in building relationship with your right audience.'
    },
    
]













class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--12">
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient"> Paid Permotions</h2>
                                    <p>Fresh From The Press Discription</p>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                     <h2 className="title theme-gradient">Our Services</h2>
                                    
{/*                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
*/}                                </div>
                            </div>
                        </div>



                        <div className="row service-main-wrapper">



                             {BusinessStrategy.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/business-strategy">
                                            <div className="service service__style--2 text-left businessstrategy">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                              {BrandIdentity.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/brand-identity">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))} 
                                
                                  {DesignDevelopment.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 " key={i}>
                                        <a href="/design-development">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}


                        </div>

                          <div className="row service-main-wrapper">


                             {SearchEngieOptimization.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/search-engine-optimization">
                                            <div className="service service__style--2 text-left SearchEngieOptimization">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))} 
                             {PayPerClick.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/paid-promotions">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                                {SocialMedia.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/social-media">
                                            <div className="service service__style--2 text-left SocialMedia">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}



                                   </div>





                    </div>
                </div>
                {/* End Service Area */}


               











                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;